<template>
    <div id="surveys" class="survey-container">
        <b-row class="survey-title">
            <b-col cols="12" class="share-experience">
                {{ $t("surveyRoute.titleText") }}
            </b-col>
        </b-row>
        <b-row class="survey-title">
            <b-col cols="12" class="share-subtext">
                {{ $t("surveyRoute.bottomTittleText") }}
            </b-col>
        </b-row>
        <b-row>
            <b-col
                v-for="survey in surveys"
                :key="survey.id"
                sm="6"
                md="3"
            >
                <div
                    class="survey-entity my-3"
                    @click="goToSurvey(survey)"
                >
                    <img
                        :src="`${survey.imageUrl}`"
                        alt=""
                    >
                    <div class="survey-info subtext">
                        {{ survey.alias }}
                        <font-awesome-icon v-if="survey.completed" :icon="[ 'far', 'check-circle' ]" class="circle text-helper" />
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import surveys from '@/api/surveys'
    import config from '@/mixins/config'

    export default {
        name: 'Surveys',
        mixins: [ config ],
        data() {
            return {
                surveys: null
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            }
        },
        created() {
            surveys.getSurveys().then(resp => {
                this.surveys = resp.data
            })
        },
        methods: {
            goToSurvey(survey) {
                if (survey && survey.id) {
                    this.$router.push({ path: `/surveys/${survey.id}` })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.survey-container {
    padding: 1rem 1rem 6rem;
}

.survey-title {
    text-align: left;
}

.share-experience {
    font-weight: bold;
    font-size: 150%;
    color: var(--secondary-color);
}

.share-subtext {
    font-size: 80%;
    color: var(--main-content-text);
}

.survey-entity {
    cursor: pointer;
    background: var(--white);
    border-radius: 10px;
    padding: 0.75rem;
}

.survey-entity img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
}

.survey-info {
    height: 50px;
    font-weight: bold;
    text-align: left;
    font-size: 80%;
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.text-helper {
    font-size: 1.25rem;
}

svg.circle path {
    color: var(--secondary-color);
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

@media screen and (min-width: 992px) {
    .survey-container {
        padding: 1rem 1rem 2rem;
    }
}
</style>
