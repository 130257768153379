import restApi from '@/utils/rest-api'

export default {
    getSurveys: () => {
        return restApi.get(`surveys`)
    },
    getSurvey: (surveyId) => {
        return restApi.get(`surveys/${surveyId}`)
    },
    submitSurvey: (surveyId, ratings) => {
        return restApi.post(`surveys/submit/${surveyId}`, { ratings })
    }
}
